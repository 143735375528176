import React, { useEffect, useRef } from "react";

import "./guepe.scss";
import guepeGif from "./images/guepe.gif";

const Guepe = () => {
    const requestRef = useRef();

    const movingGuepe = () => {
        // The 'state' will always be the initial value here
        requestRef.current = requestAnimationFrame(movingGuepe);
    }

    useEffect(() => {
        requestRef.current = requestAnimationFrame(movingGuepe);
        return () => cancelAnimationFrame(requestRef.current);
    }, []); // Make sure the effect runs only once

    return (
        <div className="guepe">
            <img src={ guepeGif } alt=""/>
        </div>
    )
}

export default Guepe
