import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./page.scss";
import Map from "../components/map";
import Guepe from "../components/guepe";
import DevisForm from "../components/DevisForm";
import { useImage } from "../components/AsyncImage";

const HeaderImage = ({ src }) => {

    const { hasLoaded, hasError } = useImage(src);

    let imageClass = '';
    let style = {};

    if (hasError) {
        return null;
    }

    if(hasLoaded){
        imageClass = 'loaded';
        style = {
            backgroundImage: `url(${src})`
        }
    }


    return(
        <div className={ 'image-header ' + imageClass} style={ style } />
    )
}

const HeaderContent = ({ acf, post, src }) => {
    return(
        <header>
            <HeaderImage src={ src } />
            <div className="inner">
                <h1>
                    {parse(post.title)}
                </h1>
                <div className="content-head">
                    {!!acf.contenthead && (
                        parse(acf.contenthead)
                    )}
                </div>
            </div>
        </header>
    )
}

const PageTemplate = ({ data: { previous, next, post } }) => {
    const featuredImage = {
        fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
        alt: post.featuredImage?.node?.alt || ``,
    }

    let src = featuredImage?.fluid?.src || '';
    let acf = post.acfPageInterne;

    return (
        <Layout post={ post }>
            <SEO title={ post.title } description={ post.excerpt } post={ post }/>

            <div className={'page ' + post.slug}>
                <section className="section content">
                    {src !== null &&
                        <HeaderContent acf={acf} post={post} src={ src } />
                    }
                    <main>
                        <div className="inner">
                            {acf?.contenudynamique?.map((data, key) => {
                                switch (data.fieldGroupName) {
                                    case "page_Acfpageinterne_Contenudynamique_Texte":
                                        return <ContenuDynamiqueTexte key={ key } data={ data } />
                                    case "page_Acfpageinterne_Contenudynamique_Texteimage":
                                        return <ContenuDynamiqueTexteImage key={ key } data={ data } />
                                    case "page_Acfpageinterne_Contenudynamique_Image":
                                        return <ContenuDynamiqueImage key={ key } data={ data } />
                                    case "page_Acfpageinterne_Contenudynamique_Carte":
                                        return <ContenuDynamiqueCarte key={ key } data={ data } />
                                    case "page_Acfpageinterne_Contenudynamique_Conseil":
                                        return <ContenuDynamiqueConseil key={ key } data={ data } />
                                    case "page_Acfpageinterne_Contenudynamique_Lien":
                                        return <ContenuDynamiqueLien key={ key } data={ data } />
                                    case "page_Acfpageinterne_Contenudynamique_Form":
                                        return <ContenuDynamiqueForm key={ key } />
                                    default:
                                        return null;
                                }
                            })}
                        </div>
                    </main>
                </section>
            </div>
        </Layout>
    )
}

export default PageTemplate;

const ContenuDynamiqueForm = () => {
    return(
        <div className="dynamic form">
            <div className="inner">
                <DevisForm />
            </div>
        </div>
    )
}

const ContenuDynamiqueTexte = ({ data }) => {
    return(
        <div className="dynamic texte">
            {data.texte &&
                <div className="inner">
                    {parse(data.texte)}
                </div>
            }
        </div>
    )
}

const ContenuDynamiqueConseil = ({ data }) => {

    return(
        <div className="dynamic conseil">
            {data.wallpaper &&
            <span className="wallp"
                  style={
                      {
                          backgroundImage: `url(${data.wallpaper.localFile.childImageSharp.fluid.src})`,
                          backgroundSize: data.wallpaperSize + "%"
                      }
                  }/>
            }
            <div className="inner">
                <span className="titre">{ parse(data.titre) }</span>
                <div className="content">
                    { parse(data.texte) }
                </div>
                {data.image &&
                    <div className="image">
                        <picture>
                            <source type="image/webp"
                                    srcSet={ data.image.localFile.childImageSharp.fluid.srcWebp }
                                    data-srcset={ data.image.localFile.childImageSharp.fluid.srcSetWebp }
                            />
                            <source type="image/jpeg" srcSet={data.image.localFile.childImageSharp.fluid.src} />
                            <img src={data.image.localFile.childImageSharp.fluid.src}
                                 alt={data.image.altText}
                            />
                        </picture>
                    </div>
                }
            </div>
        </div>
    )
}

const ContenuDynamiqueLien = ({ data }) => {

    return(
        <div className="dynamic lien">
            <div className="inner">
                {data.image &&
                    <div className="image">
                        <picture>
                            <source type="image/webp"
                                    srcSet={ data.image.localFile.childImageSharp.fluid.srcWebp }
                                    data-srcset={ data.image.localFile.childImageSharp.fluid.srcSetWebp }
                            />
                            <source type="image/jpeg" srcSet={data.image.localFile.childImageSharp.fluid.src} />
                            <img src={data.image.localFile.childImageSharp.fluid.src}
                                 alt={data.image.altText}
                            />
                        </picture>
                    </div>
                }
                {!data.image &&
                    <Guepe />
                }
                {data.texte &&
                    <span className="texte">{parse(data.texte)}</span>
                }
                <a className="call-to-action"
                   href={ data.lien.url } target={ data.lien.target }>
                    { parse( data.lien.title ) }
                </a>
            </div>
        </div>
    )
}

const ContenuDynamiqueTexteImage = ({ data }) => {

    let blocLeft, blocRight;

    let renderImage = (dir) => {
        return(
            <div className={`image ${ dir } ${data.imageFormat}`}>
                {data.image &&
                    <figure>
                        <picture>
                            <source type="image/webp"
                                    srcSet={ data.image.localFile.childImageSharp.fluid.srcWebp }
                                    data-srcset={ data.image.localFile.childImageSharp.fluid.srcSetWebp }
                            />
                            <source type="image/jpeg" srcSet={data.image.localFile.childImageSharp.fluid.src} />
                            <img src={data.image.localFile.childImageSharp.fluid.src}
                                 alt={data.image.altText}
                            />
                        </picture>
                        <figcaption className="desc">{ data.image.altText }</figcaption>
                    </figure>
                }
            </div>
        )
    }

    let renderText = (dir) => {
        return(
            <div className={`text ${ dir }`}>
                { parse( data.texte ) }
            </div>
        )
    }

    if(data.placementDeLimage === "droite"){
        blocLeft = renderText('left');
        blocRight = renderImage('right');
    } else {
        blocLeft = renderImage('left');
        blocRight = renderText('right');
    }

    return(
        <div className="dynamic texte-image">
            <div className="inner">
                <div className="bloc-left">
                    { blocLeft }
                </div>
                <div className="bloc-right">
                    { blocRight }
                </div>
            </div>
        </div>
    )
}

const ContenuDynamiqueCarte = ({ data }) => {

    let blocLeft, blocRight;

    let renderCarte = () => {
        return(
            <div className="carte">
                <Map />
            </div>
        )
    }

    let renderText = (dir) => {
        return(
            <div className={`text ${ dir }`}>
                { parse( data.texte ) }
            </div>
        )
    }

    if(data.texte) {
        if (data.placementDeLaCarte === "droite") {
            blocLeft = renderText('left');
            blocRight = renderCarte('right');
        } else {
            blocLeft = renderCarte('left');
            blocRight = renderText('right');
        }
    }

    return(
        <div className="dynamic texte-carte">
            <div className="inner">
                {data.texte &&
                [
                    <div className="bloc-left" key='1'>
                        { blocLeft }
                    </div>,
                    <div className="bloc-right" key='2'>
                        { blocRight }
                    </div>
                ]
                }
                {!data.texte && <Map />}
            </div>
        </div>
    )
}

const ContenuDynamiqueImage = ({ data }) => {

    let className = "center";

    if (data.placementDeLimage === "gauche") {
        className = "left";
    } else if(data.placementDeLimage === "droite"){
        className = "right";
    }

    return(
        <div className="dynamic image">
            <div className="inner">
                <div className={`image ` + className}>
                    {data.image &&
                        <figure>
                            <picture>
                                <source type="image/webp"
                                        srcSet={ data.image.localFile.childImageSharp.fluid.srcWebp }
                                        data-srcset={ data.image.localFile.childImageSharp.fluid.srcSetWebp }
                                />
                                <source type="image/jpeg" srcSet={data.image.localFile.childImageSharp.fluid.src} />
                                <img src={data.image.localFile.childImageSharp.fluid.src}
                                     alt={data.image.altText}
                                />
                            </picture>
                            {data.description &&
                                <figcaption className="desc">{ parse(data.description) }</figcaption>
                            }
                        </figure>
                    }
                </div>
            </div>
        </div>
    )
}

export const pageQuery = graphql`
  query PageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      slug
      date(formatString: "MMMM DD, YYYY")
      seo {
        canonical
        title
        metaDesc
        focuskw
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphAuthor
        opengraphDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
            localFile {
               childImageSharp {
                   fluid(maxWidth: 700, quality: 90, toFormat: JPG) {
                      src
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                   }
               }
            }
        }
        opengraphUrl
        opengraphSiteName
        opengraphPublishedTime
        opengraphModifiedTime
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
            localFile {
               childImageSharp {
                   fluid(maxWidth: 700, quality: 90, toFormat: JPG) {
                      src
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                   }
               }
            }
        }
        breadcrumbs {
            url
            text
        }
        cornerstone
        schema {
            pageType
            articleType
            raw
        }
        readingTime
      }
      acfPageInterne {
        contenthead
        contenudynamique {
            ... on WpPage_Acfpageinterne_Contenudynamique_Texte {
              fieldGroupName
              texte
            }
            ... on WpPage_Acfpageinterne_Contenudynamique_Conseil {
              fieldGroupName
              texte
              titre
              image {
                altText
                localFile {
                   childImageSharp {
                       fluid(maxHeight: 147, quality: 90) {
                          src
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                       }
                   }
                }
              }
              wallpaperSize
              wallpaper {
                localFile {
                   childImageSharp {
                       fluid(maxWidth: 700, quality: 80) {
                          src
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                       }
                   }
                }
              }
            }
            ... on WpPage_Acfpageinterne_Contenudynamique_Lien {
              fieldGroupName
              texte
              image {
                altText
                localFile {
                   childImageSharp {
                       fluid(maxHeight: 147, quality: 90, toFormat: JPG) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                          src
                       }
                   }
                }
              }
              lien {
                target
                title
                url
              }
            }
            ... on WpPage_Acfpageinterne_Contenudynamique_Form {
              fieldGroupName
            }
            ... on WpPage_Acfpageinterne_Contenudynamique_Texteimage {
              fieldGroupName
              texte
              placementDeLimage
              imageFormat
              image {
                altText
                localFile {
                   childImageSharp {
                       fluid(maxWidth: 1000, quality: 100, toFormat: JPG) {
                          src
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                       }
                   }
                }
              }
            }
            ... on WpPage_Acfpageinterne_Contenudynamique_Carte {
              fieldGroupName
              texte
              placementDeLaCarte
            }
            ... on WpPage_Acfpageinterne_Contenudynamique_Image {
              fieldGroupName
              placementDeLimage
              image {
                altText
                localFile {
                   childImageSharp {
                       fluid(maxWidth: 1000, quality: 100, toFormat: JPG) {
                          src
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                       }
                   }
                }
              }
              description
            }
        }
      }

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPage(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPage(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;