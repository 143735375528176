import React from "react";

import "./map.scss";
import MapGoogle from "./MapGoogle";

const Map = () => {

    return (
        <div className="map">
            <MapGoogle />
        </div>
    )
}

export default Map
